import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { BREAKPOINT } from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import heroImage from '../images/hero-djcard1.webp';
import IPhoneNotSupportedSection from '@/pages/_iphoneNotSupported';

const urlVixVerify =
  'https://au.vixverify.com/sa/customers/gecapital-ach/home.seam?ui=davidjones';

function DavidJonesAddCardPage(props) {
  const [urlFrame, setUrlFrame] = useState(null);

  function fetchIframeSource() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return urlVixVerify + '&applicationid=' + applicationId;
      }
    }
    return urlVixVerify;
  }

  useEffect(() => {
    const iframeSource = fetchIframeSource();
    setUrlFrame(iframeSource);
  }, []);

  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/david-jones/addcard/details/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your David Jones credit card"
          />
          <title>Additional Card Holder | David Jones credit card</title>
        </Helmet>

        <div
          className="pageHeader"
          css={`
            && section.lfs-pageheader {
              background: rgb(0, 0, 0);
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              && div.lfs-pageheader-hero > div {
                max-height: 400px;
              }
              && div.HeroContent {
                padding: 0 0;
              }
            }
          `}
        >
          <PageHeaderSection
            title="David Jones additional cardholder"
            subTitle="Complete your request by verifying your personal details to become an additional cardholder"
            pageImage={heroImage}
            pageImageAlt="David Jones cards"
          />
        </div>

        <IPhoneNotSupportedSection />
        <div
          css={{ clear: 'both', margin: '10px 0' }}
          className="lfs-iframe-vixverify"
        >
          {urlFrame && (
            <iframe
              css={{
                width: '100%',
                minHeight: '800px',
                border: 'none',
                padding: '10px'
              }}
              title="Verify Your Identity"
              src={urlFrame}
            />
          )}
        </div>
      </main>
    </Layout>
  );
}

export default DavidJonesAddCardPage;
